import $http from "./index";

/** 抽奖相关接口 */

// 导出抽奖记录列表
export const exportDrawRecords = (data) => {
  return $http.get("app/business/broadcast/back/broadcastsListExport", {
    params: data,
  });
};
